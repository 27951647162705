import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';
import Check from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { base_url } from '../../commons/constants';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
//Auth Auth0
import { useAuth0 } from "@auth0/auth0-react";

export default function PricingCards() {
  const { isAuthenticated,getAccessTokenSilently} = useAuth0();
  const [open, setOpen] = React.useState(false)


  const handleSubmit = async (pro,unlimited) =>{
   console.log(pro)

  try {
    const headers = new Headers(); 
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently(); 
      headers.append("Authorization", "Bearer "+ accessToken);

    }

    var requestOptions = {
      method: 'POST', 
      redirect: 'follow',
      headers: headers

      };
    fetch(encodeURI(base_url+"waitlist?pro_waitlist=True"+"&unlimited_waitlist=False"), requestOptions)
    .then(response => {
      if (response.ok) {
        setOpen(true)
        return response.json()
      }
      else if(response.status == 429){
        console.logI=("got 429")
   
      }
       
      })
    .then(result => {})
          .catch(error => {console.log('ERRRRRR', error); });
  } catch (error) {
    }
};
const handleUnlimited = async (pro,unlimited) =>{
  console.log(pro)

 try {
   const headers = new Headers(); 
   if (isAuthenticated) {
     const accessToken = await getAccessTokenSilently(); 
     headers.append("Authorization", "Bearer "+ accessToken);

   }

   var requestOptions = {
     method: 'POST', 
     redirect: 'follow',
     headers: headers

     };
   fetch(encodeURI(base_url+"waitlist?pro_waitlist=False"+"&unlimited_waitlist=True"), requestOptions)
   .then(response => {
     if (response.ok) {
      setOpen(true)
       return response.json()
     }
     else if(response.status == 429){
       console.logI=("got 429")
  
     }
      
     })
   .then(result => {})
         .catch(error => {console.log('ERRRRRR', error); });
 } catch (error) {
   }
};
  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 300px), 1fr))',
        gap: 2,
        
      }}
    >
        <Card size="lg" variant="outlined"   sx={{ bgcolor: 'white' }}>
        <Chip size="sm" variant="outlined" color="neutral">
          FREE
        </Chip>
        <Typography level="h2">Basic</Typography>
        <Divider inset="none" />
        <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
          <ListItem>
            <ListItemDecorator>
              <Check sx={{color:"green"}} />
            </ListItemDecorator>
            Search Requests (50/Day or 5/Minute)
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <CloseIcon sx={{color:"red"}} />
            </ListItemDecorator>
            Customization
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <CloseIcon  sx={{color:"red"}} />
            </ListItemDecorator>
            Copilot
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <CloseIcon  sx={{color:"red"}} />
            </ListItemDecorator>
            API Integration
          </ListItem>
        </List>
        <Divider inset="none" />
        <CardActions>
          <Typography level="title-lg" sx={{ mr: 'auto' }}>
            0${' '}
            <Typography fontSize="sm" textColor="text.tertiary">
              / month
            </Typography>
          </Typography>
          <Button
            variant="soft"
            color="neutral"
            disabled
            endDecorator={<KeyboardArrowRight />}
          >
            Active
          </Button>
        </CardActions>
      </Card>
      <Card size="lg" variant="outlined">
        <Chip size="sm" variant="outlined" color="neutral">
          BASIC
        </Chip>
        <Typography level="h2">Professional</Typography>
        <Divider inset="none" />
        <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
          <ListItem>
            <ListItemDecorator>
              <Check sx={{color:"green"}} />
            </ListItemDecorator>
            Search Requests (100/Day)
          </ListItem>
          <ListItem>
            <ListItemDecorator>
            <Check sx={{color:"green"}} />
            </ListItemDecorator>
            Customization
          </ListItem>
          <ListItem>
            <ListItemDecorator>
            <Check sx={{color:"green"}} />
            </ListItemDecorator>
            Copilot
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <CloseIcon  sx={{color:"red"}} />
            </ListItemDecorator>
            API Integration
          </ListItem>
        </List>
        <Divider inset="none" />
        <CardActions>
          <Typography level="title-lg" sx={{ mr: 'auto' }}>
            10${' '}
            <Typography fontSize="sm" textColor="text.tertiary">
              / month
            </Typography>
          </Typography>
          <Button
            variant="soft"
            color="neutral"
            onClick={() => {handleSubmit("true","false");}}
            endDecorator={<KeyboardArrowRight />}
          >
           Join Wait List
          </Button>
        </CardActions>
      </Card>
      <Card
        size="lg"
        variant="solid"
        color="neutral"
        invertedColors
        sx={{ bgcolor: 'neutral.900' }}
      >
        <Chip size="sm" variant="outlined">
          MOST POPULAR
        </Chip>
        <Typography level="h2">Unlimited</Typography>
        <Divider inset="none" />
        <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
          <ListItem>
            <ListItemDecorator>
              <Check sx={{color:"green"}} />
            </ListItemDecorator>
            Unlimited Search Requests
          </ListItem>
          <ListItem>
            <ListItemDecorator>
            <Check sx={{color:"green"}} />
            </ListItemDecorator>
            Customization
          </ListItem>
          <ListItem>
            <ListItemDecorator>
            <Check sx={{color:"green"}} />
            </ListItemDecorator>
            Copilot
          </ListItem>
          <ListItem>
            <ListItemDecorator>
            <Check sx={{color:"green"}} />
            </ListItemDecorator>
            API Integration
          </ListItem>
        </List>
        <Divider inset="none" />
        <CardActions>
          <Typography level="title-lg" sx={{ mr: 'auto' }}>
            20${' '}
            <Typography fontSize="sm" textColor="text.tertiary">
              / month
            </Typography>
          </Typography>
          <Button endDecorator={<KeyboardArrowRight />}  onClick={() => {handleUnlimited("true","false");}}> Join Wait List</Button>
        </CardActions>
      </Card>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>
           You are now in waiting list, we will reach out to you once we can.
          </DialogContent>
          <DialogActions>
      
            <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
              Ok
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </Box>
  );
}

import * as React from 'react';
import Box from '@mui/joy/Box';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { useNavigate } from 'react-router-dom';



export default function ChatListItem(props) {
    const { item} = props;
    const navigate = useNavigate();

//   const selected = selectedChatId === id;
  return (
    <React.Fragment>
      <ListItem sx={{   marginLeft: '20px', }}  > 
        <ListItemButton
         
         onClick={()=>{navigate('/q', {state: item})}}
        
          color="neutral"
          sx={{
            flexDirection: 'column',
            alignItems: 'initial',
            gap: 1,
          }}
        >
          <Stack direction="row" spacing={1.5}>

            <Box sx={{ flex: 1, }}>
              <Typography    sx={{ display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,}}>{item.question}</Typography>

            </Box>
          
          </Stack>
    
        </ListItemButton>
      </ListItem>

    </React.Fragment>
  );
}
import * as React from 'react';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import { Box, Container, Typography } from '@mui/joy';
import Skeleton from '@mui/joy/Skeleton';
import Sources from './Sources';

import logo from '../commons/logo.png'
import { capitalize } from '@mui/material';
import { CopyAllOutlined, Share, ThumbDown, ThumbUp } from '@mui/icons-material';
import { base_url, self_url } from '../commons/constants';
import CopyToClipboard from 'react-copy-to-clipboard';
import Snackbar from '@mui/joy/Snackbar';
//Auth Auth0
import { useAuth0 } from "@auth0/auth0-react";

import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

export default function Ask({setQuestion}) {

  let base_url = process.env.REACT_APP_API_ENDPOINT;

  const [quotaHit, setQuotaHit] = React.useState(false);
  const [copy, setCopy] = React.useState(false);


  const [data, setData] = React.useState({
    id:'',
    question: '',
    answer: '',
    status: 'initial',
    sources: [{}],
  });
  const { isAuthenticated,getAccessTokenSilently} = useAuth0();


    const handleSubmit = async (event) => {
    event.preventDefault();
    setData((current) => ({ ...current, status: 'loading' , answer:''}));
    try {
      const headers = new Headers(); 
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently(); 
        headers.append("Authorization", "Bearer "+ accessToken);
  
      }

      var requestOptions = {
        method: 'POST', 
        redirect: 'follow',
        headers: headers

        };
      fetch(encodeURI(base_url+"ask/"+data.question), requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        else if(response.status == 429){
          console.logI=("got 429")
           setQuotaHit(true)
        }
         
        })
      .then(result => {  sessionStorage.setItem('key',JSON.stringify({id:result.id, question:data.question})); setData({id:result.id,question:data.question, answer: result.answer, status: 'sent', sources: result.results});})
      .catch(error => {console.log('ERRRRRR', error); setData({question:data.question,status: 'failure'})});
    } catch (error) {
      setData((current) => ({ ...current, status: 'failure' }));
    }
  };

  return (

    <Container fixed
    
    sx={theme => ({
      color: `rgba(${theme.vars.palette.background})`,
  
      mx: 'auto', // margin left & right
      my: 6, // margin top & bottom
      py: 3, // padding top & bottom
      px: 4, // padding left & right  
      gap: 2,
      borderRadius: 5,
      textAlign: 'center'
    
 
    
    
    })}
    >
      <img  height="50px"src={logo} alt=""></img>
      { <>
        <Typography  level="h3" sx={{margin:"30px"}} >
          Ask the Internet for straight answers
          </Typography>
         
      <Box sx={{ textAlign: 'left'}}>
    
    <form onSubmit={handleSubmit} id="demo">
      <FormControl>
        
        <Input
          sx={{ '--Input-decoratorChildHeight': '55px' ,borderLeftRadius:0 }}
          placeholder="Why is the color of sky blue ?"
          type="text"
          required
          value={data.question}
          onChange={(event) =>
            setData({ question: event.target.value, status: 'initial'})
          }
          error={data.status === 'failure'}
          endDecorator={
            <Button
              sx={(theme) => ({ backgroundColor: theme.vars.palette.neutral.softActiveColor ,borderTopLeftRadius: 0, borderBottomLeftRadius: 0})}
              variant="solid"
            
              loading={data.status === 'loading'}
              type="submit"
       
            >
              Ask
            </Button>
          }
        />
        {data.status === 'failure' && (
          <FormHelperText
            sx={(theme) => ({ color: theme.vars.palette.danger[400] })}
          >
            Oops! something went wrong, please try again later.
          </FormHelperText>
        )}

      
      </FormControl>
    </form>
    <div sx={{marginTop: '30'}}>
        {data.status === 'sent' && <Typography level="h3" sx={{ mb: 0.5 , marginTop: "50px"}}>{capitalize(data.question)}</Typography> }
          <Typography sx={{ mb: 0.5 , marginTop: "50px"}}
           
          > 
            {data.status === 'loading' && <><Skeleton animation="wave" variant="text" /> <Skeleton animation="wave" variant="text" /><Skeleton animation="wave" variant="text" /></>}
            {data.status === 'sent' && <>
            
            <CopyToClipboard text={self_url+"q/"+data.id}>
            
            <Button variant="plain" color="neutral" onClick={()=>{setCopy(true)}} startDecorator={<Share  />} ></Button>
            </CopyToClipboard>  
            <div dangerouslySetInnerHTML={{__html: data.answer}}></div> </>    }
            
          </Typography>
          </div>
          {data.status === 'sent' && data.sources && <Sources  input={data.sources} />}
    </Box>
        </>}
        <Modal open={quotaHit} onClose={() => {setQuotaHit(false)}}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Upgrade to Pro
          </DialogTitle>
        
          <DialogContent>
            You have exhausted free tier usage. Please upgrade to Pro to get limtless access.
          </DialogContent>
          <DialogActions>
     
            <Button variant="plain" color="neutral" onClick={() => {setQuotaHit(false)}}>
              Ok
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <Snackbar
   anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
       autoHideDuration={3000}
        open={copy}
        variant="soft"
        color="success"
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          setCopy(false);
        }}
    
      >
        {copy ? "Link copied to clipboard":""}
        
      </Snackbar>
     
    </Container>
    // </Grid>
    // </Grid>
  );
}
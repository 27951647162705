import * as React from 'react';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import { styled } from '@mui/joy/styles';
import { Card,Typography, CardContent, Avatar, Box,} from '@mui/joy';
import { Link } from 'react-router-dom';


export default function Sources(props) {
  return (

    <div >
           <Typography  level="h6" sx={{ marginTop: "50px", marginBottom: "10px"}}> Derivated from </Typography>
           <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 200px), 1fr))',
        gap: 2,
    
      }}
    >
   
       { props.input.map( (source) =>
    
        <Card 
        hoverable
        orientation="horizontal"
        // onClick = {() =>{window.open(source.href, "_blank") }}
        sx={{
            
            '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },      
          }}>
            
             <Avatar alt="Remy Sharp" size="sm" src={"https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url="+ source.link +"&size=64"} />
      
        <CardContent>
            <Link target="_blank" to={source.link}  style={{ textDecoration: 'none' }}>
           <Typography level="title-sm" sx={{ display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,}}>{source.title}</Typography>
        </Link>
        </CardContent>
        </Card>)}
      </Box>
    </div>
  );
}
import QRCode from 'qrcode'
import * as React from 'react';
import { useState, useEffect } from "react";


  export default function Register() {
    const [qrCodeURL, setQRCodeURL] = React.useState('');

    useEffect(() => {
        //Runs only on the first render
        // With promises
        QRCode.toCanvas('otpauth://totp/Secure%20App:tsameerc%40google.com?secret=JBSWY3DPEHPK3PXP&issuer=PiVault',{ errorCorrectionLevel: 'H' }, function (err, canvas) {
            if (err) throw err
          
            var container = document.getElementById('root')
            container.appendChild(canvas)})

      },[]);

    return (
        <><p>{qrCodeURL}</p></>
    );
}
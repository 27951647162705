import * as React from 'react';
import { useColorScheme } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ListDivider from '@mui/joy/ListDivider';
import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import BookRoundedIcon from '@mui/icons-material/BookRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Navigation from './Navigation';
import LoginButton from './Auth/LoginButton';

//Auth Auth0
import { useAuth0 } from "@auth0/auth0-react";
import { Sheet } from '@mui/joy';
import Sidebar from './Sidebar';
import GlobalStyles from '@mui/joy/GlobalStyles';
 function openSidebar() {
  if (typeof window !== 'undefined') {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
  }
}

 function closeSidebar() {
  if (typeof window !== 'undefined') {
    document.documentElement.style.removeProperty('--SideNavigation-slideIn');
    document.body.style.removeProperty('overflow');
  }
}
function toggleSidebar() {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--SideNavigation-slideIn');
    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
}

function ColorSchemeToggle() { 
  const { mode, setMode } = useColorScheme();
  
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return <IconButton size="sm" variant="outlined" color="primary" />;
  }
  return (
    <Tooltip title="Change theme" variant="outlined">
      <IconButton
        id="toggle-mode"
        size="sm"
        variant="plain"
        color="neutral"
        sx={{ alignSelf: 'center' }}
        onClick={() => {
          if (mode === 'light') {
            setMode('dark');
          } else {
            setMode('light');
          }
        }}
      >
        {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
      </IconButton>
    </Tooltip>
  );
}

export default function Header() {
  const [open, setOpen] = React.useState(false);
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  if (isLoading) {
    return <div>Loading ...</div>;
  }
  return (
//     <Sheet
//     sx={{
//       display: { xs: 'flex', md: 'none' },
//       alignItems: 'center',
//       justifyContent: 'space-between',
//       position: 'fixed',
//       top: 0,
//       width: '100vw',
//       height: 'var(--Header-height)',
//       zIndex: 9995,
//       p: 2,
//       gap: 1,

//       borderColor: 'background.level1',
   
//     }}
//   >
//       <Stack
//         direction="row"
//         justifyContent="center"
//         alignItems="center"
//         spacing={1}
//         sx={{ display: { xs: 'none', sm: 'flex' } }}
//         variant="outlined"
//       >
//         {/* <IconButton
//           size="md"
//           variant="outlined"
//           color="neutral"
//           sx={{
//             display: { xs: 'none', sm: 'inline-flex' },
//             borderRadius: '50%',
//           }}
//         >
//           <LanguageRoundedIcon />
//         </IconButton>
//         <Button
//           variant="plain"
//           color="neutral"
//           aria-pressed="true"
//           component="a"
//           href="/passwords"
//           size="sm"
//           sx={{ alignSelf: 'center' }}
//         >
//           Passwords
//         </Button>
//         <Button
//           variant="plain"
//           color="neutral"
//           component="a"
//           href="/accounts"
//           size="sm"
//           sx={{ alignSelf: 'center' }}
//         >
//           Accounts
//         </Button>
//         <Button
//           variant="plain"
//           color="neutral"
//           component="a"
//           href="/files"
//           size="sm"
//           sx={{ alignSelf: 'center' }}
//         >
//           Files
//         </Button> */}
//       </Stack>
//       <Box sx={{ display: { xs: 'inline-flex', sm: 'flex', md:'none' } }}>
//         <IconButton variant="plain" color="neutral" onClick={() => setOpen(true)}>
//           <MenuRoundedIcon />
//         </IconButton>
//         <Drawer
//           sx={{ display: { xs: 'inline-flex', sm: 'inline-flex',  md:'none'} }}
//           open={open}
//           onClose={() => setOpen(false)}
//         >
//           <ModalClose />
//           <DialogTitle>Valult.</DialogTitle>
//           <Box sx={{ px: 1 }}>
//          <Sidebar></Sidebar>
//           </Box>
//         </Drawer>
//       </Box>

//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'row',
//           gap: 3.5,
//           alignItems: 'center',
//         }}
//       >
//         {/* <Input
//           size="sm"
//           variant="outlined"
//           placeholder="Search anything…"
//           startDecorator={<SearchRoundedIcon color="primary" />}
//           endDecorator={
//             <IconButton
//               variant="outlined"
//               color="neutral"
//               sx={{ bgcolor: 'background.level1' }}
//             >
//               <Typography level="title-sm" textColor="text.icon">
//                 ⌘ K
//               </Typography>
//             </IconButton>
//           }
//           sx={{
//             alignSelf: 'center',
//             display: {
//               xs: 'none',
//               sm: 'flex',
//             },
//           }}
//         />
//         <IconButton
//           size="sm"
//           variant="outlined"
//           color="neutral"
//           sx={{ display: { xs: 'inline-flex', sm: 'none' }, alignSelf: 'center' }}
//         >
//           <SearchRoundedIcon /> 
//         </IconButton> */}
//         { !isAuthenticated && <LoginButton/>}
//         { isAuthenticated &&
//         <Dropdown>
//           <MenuButton
//             variant="plain"
//             size="sm"
//             sx={{ maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px' }}
//           >
//             <Avatar
         
//               sx={{ maxWidth: '32px', maxHeight: '32px' }}
//             />
//           </MenuButton>
//           <Menu
//             placement="bottom-end"
//             size="sm"
//             sx={{
//               zIndex: '99999',
//               p: 1,
//               gap: 1,
//               '--ListItem-radius': 'var(--joy-radius-sm)',
//             }}
//           >
//             <MenuItem>
//               <Box
//                 sx={{
//                   display: 'flex',
//                   alignItems: 'center',
//                 }}
//               >
//                 <Avatar
//                   src="https://i.pravatar.cc/40?img=2"
//                   srcSet="https://i.pravatar.cc/80?img=2"
//                   sx={{ borderRadius: '50%' }}
//                 />
//                 <Box sx={{ ml: 1.5 }}>
//                   <Typography level="title-sm" textColor="text.primary">
//                     {user.name}
//                   </Typography>
//                   <Typography level="body-xs" textColor="text.tertiary">
                    
//                   </Typography>
//                 </Box>
//               </Box>
//             </MenuItem>
//             <ListDivider />
//             <MenuItem>
//               <HelpRoundedIcon />
//               Help
//             </MenuItem>
//             <MenuItem>
//               <SettingsRoundedIcon />
//               Settings
//             </MenuItem>
//             <ListDivider />

//             <ListDivider />
//             <MenuItem onClick={()=>{logout({ logoutParams: { returnTo: window.location.origin } })}}>
//               <LogoutRoundedIcon />
//               Log out
//             </MenuItem>
//           </Menu>
//         </Dropdown>
// }
//       </Box>
//     </Sheet>
<Sheet
sx={{
  display: { xs: 'flex', sm: 'none' },
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'fixed',
  top: 0,
  width: '100vw',
  height: 'var(--Header-height)',
  zIndex: 9995,
  p: 2,
  gap: 1,
  borderBottom: '1px solid',
  borderColor: 'background.level1',
  boxShadow: 'sm',
}}
>
<GlobalStyles
  styles={(theme) => ({
    ':root': {
      '--Header-height': '52px',
      [theme.breakpoints.up('lg')]: {
        '--Header-height': '0px',
      },
    },
  })}
/>
<IconButton
  onClick={() => toggleSidebar()}
  variant="outlined"
  color="neutral"
  size="sm"
>
  <MenuRoundedIcon />
</IconButton>
{ !isAuthenticated && <LoginButton/>}
</Sheet>
  );
}

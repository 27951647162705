import * as React from 'react';
import { useEffect, useRef } from "react";
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import LinearProgress from '@mui/joy/LinearProgress';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DeleteForever from '@mui/icons-material/DeleteForever';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import ChatListItem from './Query/QueryItem';

//Auth Auth0
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from './Auth/LoginButton';


import logo from '../commons/logo.png'

import { useNavigate } from 'react-router-dom';
import useInterval from './hooks/useInternal';
import { IconButtonRoot } from '@mui/joy/IconButton/IconButton';
import { MenuRounded } from '@mui/icons-material';
import { closeSidebar } from '../commons/Utils';
import ConfirmLogOut from './Auth/ConfirmLogout';
import TagManager from 'react-gtm-module/dist/TagManager';


export default function Sidebar({newQuestion}) {
    let base_url = process.env.REACT_APP_API_ENDPOINT;
    const [isLogout, setIsLogout] = React.useState(false);
    const navigate = useNavigate();
    const [data, setData] = React.useState([]);
    const { user, isAuthenticated, logout , getAccessTokenSilently} = useAuth0();
    useInterval(() => {
      var val = JSON.parse(sessionStorage.getItem('key'))
      if (val != null) {   
           if (!data.some(item => val.id === item.id))
             setData((oldArray) => [val,...oldArray]);   
      }
      
    }, 2000);
    
    useEffect(() => {
    

      const getUserMetadata = async () => {
        try {
            const token = await getAccessTokenSilently();
            const header = new Headers();
            header.append("Authorization", "Bearer "+ token)
            // Replace timeout with real backend operation
            var requestOptions = {
              method: 'GET', 
              redirect: 'follow',
              headers: header
              };
            fetch(base_url+"answers", requestOptions)
            .then(response => response.json())
            .then(result => { setData(result);})
            .catch(error => {console.log('error', error);});
          } catch (error) {
           
          }
        };

        getUserMetadata();
      }, [getAccessTokenSilently, base_url]);
  return (
  
 

    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '240px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '260px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <img  height="50px"src={logo} alt=""></img>
        <Typography level="title-lg" onClick={()=> navigate("/ask")}>Derivate</Typography>
         { !isAuthenticated && <LoginButton sx={{  display:{xs:'none'}}}/>}

    </Box>

    <List
          size="sm"
          sx={{
            
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
             <ListItem sx={{   marginLeft: '20px', marginBottom: '20px'}}  > 
        <ListItemButton
         
         onClick={()=>{navigate('/ask');  navigate(0);}}
        
          sx={{
            flexDirection: 'column',
            alignItems: 'initial',
            gap: 5,
          }}
        >
            New Question
            
        </ListItemButton>
       
        </ListItem>
        

        <ListItem sx={{   marginLeft: '20px'}}  > 
       
        <Typography level="title-md" >Your questions</Typography>
        </ListItem>
        
        <Divider />
        </List>
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 0,
          },
        }}
      >
     
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
              {isAuthenticated  && data.map((val)=> 
            
            <ChatListItem item={val}></ChatListItem>
       )}
   
    
        </List>


      
      </Box>
      <Divider />
     { isAuthenticated && <>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Avatar
          variant="outlined"
          size="sm"
    
        />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">{user.name}</Typography>
          <Typography level="body-xs">{user.email}</Typography>
        </Box>
        <IconButton size="sm" variant="plain" color="neutral" onClick={()=>{setIsLogout(true)}}>
          <LogoutRoundedIcon />
        </IconButton>
      </Box>
      <Card
          invertedColors
          variant="soft"
          color="neutral"
          size="sm"
          sx={{ boxShadow: 'none' }}
        >
          {/* <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography level="title-sm">Used Quota</Typography>
            <IconButton size="sm">
              <CloseRoundedIcon />
            </IconButton>
          </Stack>
          <Typography level="body-xs">
          You have used 80% of your available quota. Need more?
          </Typography>
          <LinearProgress variant="outlined" value={80} determinate sx={{ my: 1 }} /> */}
          <Button size="sm" variant="solid" onClick={()=> {TagManager.dataLayer({
   dataLayer: {
       event: 'upgradeToPro', // event name declared during initialization
       userId: user.email,
      // add more data you want here
   }
}); navigate("/plans");}}>
            Upgrade to Pro
          </Button>
        </Card>
        </>
     }
     <Modal open={isLogout} onClose={() => {setIsLogout(false)}}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>
            Are you sure you want to log out?
          </DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={()=>{logout({ logoutParams: { returnTo: window.location.origin } })}}>
              Yes
            </Button>
            <Button variant="plain" color="neutral" onClick={() => {setIsLogout(false)}}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </Sheet>
    
  
  
  );
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/inter';
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from '@auth0/auth0-react';
import TagManager from 'react-gtm-module'

const root = ReactDOM.createRoot(document.getElementById('root'));

const tagManagerArgs = {
  gtmId: 'GTM-MQGN5G9F',
  events: {
  logIn: 'Log In',
  signUp: 'Sign Up',
  upgradeToPro: 'Upgrade to Pro',
  upgradePro:'Waitlist Pro',
  upgradeUnlimited:'Waitlist Unlimited',
  }
}
TagManager.initialize(tagManagerArgs)
root.render(

    <BrowserRouter>
    <Auth0Provider
    domain="derivate.us.auth0.com"
    clientId="Np8z7hwgg4qeSoeuKQni1zbpfEKbfOK9"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://derivate.in",
    }}
  >
     <App />
     </Auth0Provider>
    </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

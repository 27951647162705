import * as React from 'react';
import Button from '@mui/joy/Button';
import { Box, Container, Typography } from '@mui/joy';
import Sources from './Sources';

import logo from '../commons/logo.png'
import { capitalize } from '@mui/material';
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

import ButtonGroup from '@mui/joy/ButtonGroup';


import { CopyAllOutlined, Share, ThumbDown, ThumbUp } from '@mui/icons-material';
import { base_url, self_url } from '../commons/constants';
import CopyToClipboard from 'react-copy-to-clipboard';
import Snackbar from '@mui/joy/Snackbar';

export default function MyQuestions(props) {

  const { qID } = useParams();
  const { state } = useLocation();
  const [data, setData] = React.useState([]);
  const [copy, setCopy] = React.useState(false);
  const [feedback, setFeedback] = React.useState(false);
  let id = qID === undefined ? state.id:qID;
  React.useEffect(()=>{
  
  
    // let id = qID === undefined ? state.id:qID;
    fetch(base_url+"answers/"+id)
    .then(response => response.json())
    .then(result => {setData(result);})
    .catch(error => {console.log('error', error);});

  },[state])
 
  const sendfeedback = (feedback) => {
    var requestOptions = {
      method: 'PUT', 
      redirect: 'follow'

      };
    fetch(base_url+'answer/'+id+'/feed/'+feedback, requestOptions)
    .then(response => {setFeedback(true)})
  }

  return (

    <Container fixed
    
    sx={theme => ({
      color: `rgba(${theme.vars.palette.background})`,
      maxWidth:'500px',
      mx: 'auto', // margin left & right
      my: 4, // margin top & bottom
      py: 3, // padding top & bottom
      px: 4, // padding left & right  
      gap: 2,
      borderRadius: 5,
      textAlign: 'center'
    
 
    
    
    })}
    >
      <img  alt="" height="50px"src={logo}></img>
  


    {data.length >0  && <>
    <Box sx={{ textAlign: 'left'}}>
 
    <div sx={{marginTop: '30'}}>
     <Typography level="h3" sx={{ mb: 0.5 , marginTop: "50px"}}>{capitalize(String(data.at(0).question))}</Typography> 
     <CopyToClipboard text={self_url+"q/"+data.at(0).id}>
        <Button variant="plain" color="neutral" onClick={()=>{setCopy(true)}} startDecorator={<Share  />} ></Button>
        </CopyToClipboard>  <Button variant="plain" color="neutral" onClick={()=>{sendfeedback("1")}} startDecorator={<ThumbUp  />} ></Button> <Button variant="plain" color="neutral" onClick={()=>{sendfeedback("0")}} startDecorator={<ThumbDown  />} ></Button>
      
    <Typography sx={{ mb: 0.5 , marginTop: "50px"}}
           
          > 
          {data.at(0).response.answer}
          </Typography>
          </div>
         {data.at(0).response.results && <Sources  input={data.at(0).response.results} />}

          {/* <form onSubmit={handleSubmit} id="demo">
      <FormControl sx={{marginTop:5}}>
        
        <Input
          sx={{ '--Input-decoratorChildHeight': '55px' ,borderLeftRadius:0 }}
          placeholder= {state.question}
          type="text"
          required
          value={data.question}
          onChange={(event) =>
            setData({ question: event.target.value, status: 'initial'})
          }
          error={data.status === 'failure'}
          endDecorator={
            <Button
              sx={(theme) => ({ backgroundColor: theme.vars.palette.neutral.softActiveColor ,borderTopLeftRadius: 0, borderBottomLeftRadius: 0})}
              variant="solid"
            
              loading={data.status === 'loading'}
              type="submit"
       
            >
              Ask a follow up
            </Button>
          }
        />
        </FormControl>
        </form> */}
          </Box>
      </>}
      <Snackbar
   anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
       autoHideDuration={3000}
        open={copy}
        variant="soft"
        color="success"
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          setCopy(false);
        }}
    
      >
        {copy ? "Link copied to clipboard":""}
        
      </Snackbar>
      <Snackbar
   anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
       autoHideDuration={3000}
        open={feedback}
        variant="soft"
        color="success"
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          setFeedback(false);
        }}
    
      >
        {feedback ? "Thanks for the feedback!":""}
        
      </Snackbar>
    </Container>
    // </Grid>
    // </Grid>
  );
}
import * as React from 'react';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import { Button,Typography,ModalClose,Input ,IconButton} from '@mui/joy';
import Modal from '@mui/joy/Modal';
import { useEffect } from "react";

import VisibilityIcon from '@mui/icons-material/Visibility';
import useToken from '../auth/useToken';
import logout from '../auth/logout';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import PasswordList from './PasswordList';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Gmail', 'tsameerc@gmail.com', 'password123', 'new passowrd', 4.0),
  createData('Gmail', 'tsameerc@gmail.com', 'password123', 'new passowrd', 4.0),
  createData('Gmail', 'tsameerc@gmail.com', 'password123', 'new passowrd', 4.0),
  createData('Gmail', 'tsameerc@gmail.com', 'password123', 'new passowrd', 4.0),
  createData('Gmail', 'tsameerc@gmail.com', 'password123', 'new passowrd', 4.0),
];


export default function Passwords() {
    let base_url = process.env.REACT_APP_API_ENDPOINT;
    const {token, setToken} = useToken();
    const [open, setOpen] = React.useState(false);
    const [openOnDelete, setOpenOnDelete] = React.useState(false);
    const [passwords, setPasswords] = React.useState([])
    const  [showMap, setShowMap] = React.useState(new Map());

    const[passowrd, setPassword] = React.useState({id: "",acc_name:"", user_name:"", password: "", comment: "", last_updated_at:"", visible:false})

    const onDeleteModal = (row) => {
      setPassword(row)
      setOpenOnDelete(true)
    }


    const onUpdateModal = (row) => {
      setPassword(row)
      setOpen(true)
    }

    const deletePassword = (id) => {
      // 
      var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+token)

        var raw = JSON.stringify(passowrd);

        var requestOptions = {
        method: 'DELETE', 
        body: raw,
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(base_url+"password/", requestOptions)
        .then(response => response.text())
        .then(result => {  setOpenOnDelete(false);window.location.reload(false); })
        .catch(error => {   setOpenOnDelete(false);console.log('error', error);window.location.reload(false);});
    }
    const addPassword = (e) => {
        e.preventDefault();
       
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+token)

        var raw = JSON.stringify(passowrd);

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(base_url+"password", requestOptions)
        .then(response => response.text())
        .then(result => { setOpen(false); window.location.reload(false);})
        .catch(error => {setOpen(false);console.log('error', error);window.location.reload(false);});
      }


      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setPassword((prevProps) => ({
          ...prevProps,
          [name]: value
        }));
      };

    useEffect(() => {

      var myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer "+token)
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
let url = process.env.REACT_APP_API_ENDPOINT+"passwords"
fetch(url, requestOptions)
  .then(response => {
    if (!response.ok) {

      if (response.status === 401) {
        logout();
      }
      throw new Error("HTTP status " + response.status);
    }
    return response.json();
  })
  .then(result =>  setPasswords(result))
  .catch(error => console.log('error', error));


    },[])

    const onChange = (key, value) =>{
      
      setShowMap(new Map([...showMap, [key, value]]));
    }
  return (

    <Sheet sx={{
        width: 1080,
        mx: 'auto', // margin left & right
        my: 4, // margin top & bottom
        py: 3, // padding top & bottom
        px: 2, // padding left & right
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRadius: 5,
      
      }}
      variant="outlined">
           <PasswordList></PasswordList>
      <FormControl orientation="horizontal" sx={{ mb: 2, ml: 1 }}>
        <FormLabel></FormLabel>
        <Button onClick={() => setOpen(true)}>Add Password</Button>
        </FormControl>
        <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openOnDelete}
        onClose={() => setOpenOnDelete(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
    
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>
            Are you sure you want to delete the password entry?
          </DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={() => deletePassword()}>
              Discard notes
            </Button>
            <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
        <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
         <Sheet
          variant="outlined"
          sx={{
            width: 500,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 'md',
            gap: 2,
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h5"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            Add a new password
          </Typography>
          <form onSubmit={addPassword}>
          <FormControl>

            <FormLabel>Account</FormLabel>
            <Input
              // html input attribute
              name="acc_name"
              type="text"
              value={passowrd.acc_name}
              placeholder="Gmail"
              onChange={handleInputChange}
            />
        </FormControl>
        <FormControl>
            <FormLabel>Username</FormLabel>
            <Input
              // html input attribute
              name="user_name"
              type="text"
              value={passowrd.user_name}
              placeholder="johndoe@email.com"
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Password</FormLabel>
            <Input
              // html input attribute
              name="password"
              value={passowrd.password}
              type="password"
              onChange={handleInputChange}

            />
          </FormControl>
          <FormControl>
            <FormLabel>Comment</FormLabel>
            <Input
              // html input attribute
              name="comment"
              value={passowrd.comment}
              type="textarea"
              onChange={handleInputChange}
            />
          </FormControl>
          <Button sx={{width:100}} type="submit" >Submit</Button>
          </form>
          </Sheet>
        </Modal>
    
      
      <Table aria-label="striped table" stripe='odd' sx={{overflow: "auto" }}>
        <thead>
          <tr>
            <th>Accounts</th>
            <th>Username</th>
            <th>Password</th>
            <th>Comment</th>
        
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {passwords.map((row) => (
            <tr key={row.id}>
              <td>{row.acc_name}</td>
              <td>{row.user_name}</td>
              <td>{showMap.get(row.user_name) ?row.password: "********"}<IconButton onClick={() => onChange(row.user_name,!showMap.get(row.user_name) &&true)}><VisibilityIcon></VisibilityIcon></IconButton></td> 
              <td>{row.comment}</td>
     
            
              <td><Button variant="outlined" onClick={()=>{onUpdateModal(row)}} >Update</Button> <Button variant="outlined" onClick={() => {onDeleteModal(row)}}>Del</Button></td>
            </tr>
          ))}
        </tbody>
      </Table>

    </Sheet>
  );
}
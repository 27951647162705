import * as React from 'react';
import { Routes, Route, useHistory ,useLocation, Link  } from "react-router-dom"
import Login from './pages/Login';
import { CssVarsProvider  } from '@mui/joy/styles';
import Dashboard from './pages/Dashboard';
import Register from './pages/Register';
import useToken from './auth/useToken';
import Passwords from './components/Passwords';
import TableFiles from './components/FileTable';
import Header from './components/Header';
import Accounts from './components/Accounts';
import Ask from './components/Ask';
import { Box, CssBaseline } from '@mui/joy';
import Sidebar from './components/Sidebar';
import MyQuestions from './components/MyQuestions';
import PricingCards from './components/Auth/Plans';
import Pay from './components/Pay/Pay';
import Blog from './components/Blog';

export default function App(props) {
 
  const [question, setQuestion] = React.useState({});
  const {token, setToken} = useToken();
  const location = useLocation()

  // if(!token && location.pathname != "/") {
  //  if(!token) {   
    // return <Ask/>
  //   return <Login setToken={setToken} />
  // }
  return (
  


          <CssVarsProvider>
          <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <Header></Header>
      <Sidebar question={question} />
      {/* <Header></Header> */}
      <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: 'calc(12px + var(--Header-height))',
              sm: 'calc(12px + var(--Header-height))',
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
          }}
        >
           <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* <Ask savedQuestion={question} /> */}
    <Routes>
    <Route path="/" element={ <Ask/> } />
       <Route path="/register" element={ <Register/> } /> 
      <Route path="/home" element={ <Dashboard/> } />
      <Route path="/passwords" element={ <Passwords/> } />
      <Route path="/files" element={ <TableFiles/> } />
      <Route path="/accounts" element={ <Accounts/> } />
      <Route path="/ask" element={ <Ask setQuestion={setQuestion}/> } />
      <Route path="/q" element={ <MyQuestions /> } />
      <Route path="/q/:qID" element={ <MyQuestions /> } />
      <Route path="/plans" element={ <PricingCards /> } />
      <Route path="/pay" element={ <Pay /> } />
      <Route path="/blog" element={ <Blog /> } />
    </Routes>
    </Box>
    <Box sx={{position: "fixed",
  left: "100px",
  bottom: "10px",
  right: 0,}} ><center><Link style={{textDecoration: "none" , color: "black"}} to="https://forms.gle/71wYqyJgWjy19LdL9"> Contact</Link>&nbsp;&nbsp;<Link style={{textDecoration: "none", color: "black"}} to={"/blog"}> Blog</Link> </center></Box>
    </Box>
   
    </Box>
    <CssBaseline />
    </CssVarsProvider>

  );
}
import * as React from 'react';
import Sheet from '@mui/joy/Sheet';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import Passwords from '../components/Passwords';
import Accounts from '../components/Accounts';
import TableFiles from '../components/FileTable';
import { Button } from '@mui/joy';
import Header from '../components/Header';

export default function Dashboard() {
  return (
<>

    <Sheet sx={{}}>
              <Tabs aria-label="tabs" defaultValue={0} sx={{ bgcolor: 'transparent', width:1080,
        mx: 'auto', // margin left & right
        my: 4, // margin top & bottom
        py: 3, // padding top & bottom
        px: 2, // padding left & right
        display: 'flex',
        flexDirection: 'column', }}>
        
      <TabList
        disableUnderline
        sx={{
          p: 0.5,
          gap: 0.5,
          borderRadius: 'xl',
          bgcolor: 'background.level1',
          [`& .${tabClasses.root}[aria-selected="true"]`]: {
            boxShadow: 'sm',
            bgcolor: 'background.surface',
          },
        }}
      >
        <Tab disableIndicator>Passwords</Tab>
        <Tab disableIndicator>Accounts</Tab>
        <Tab disableIndicator>Important Docs</Tab>
        <Tab disableIndicator>Support</Tab>
      </TabList>
   
    <TabPanel value={0}>
   
    <Passwords></Passwords>
  </TabPanel>
  <TabPanel value={1}>
    <Accounts></Accounts>
  </TabPanel>
  <TabPanel value={2}>
    <TableFiles></TableFiles>
  </TabPanel>
  </Tabs>
  </Sheet>
</>
  );
}
import * as React from 'react';

import  { ReactComponent as Flowchart}  from '../commons/flowchart.svg'
import { Container, Typography } from '@mui/joy';


export default function Blog(props) {
  return (

    <div >
        <Container fixed
    
    sx={theme => ({
      color: `rgba(${theme.vars.palette.background})`,
      maxWidth:'500px',
      mx: 'auto', // margin left & right
      my: 4, // margin top & bottom
      py: 3, // padding top & bottom
      px: 4, // padding left & right  
      gap: 2,
      borderRadius: 5,
      textAlign: 'center'
    
 
    
    
    })}
    >
        <Typography level="h4" >How Derivate AI works</Typography>
        <Typography sx={{margin:"50px"}} level="text" >It started with two objectives, use large language models to do something cool and make use of the idle Raspberry Pi 4.</Typography>
      <Flowchart ></Flowchart>
      
      
      </Container>
    </div>
  );
}